export const preSaleContractAddress =
  "0xc52989f65b5253b5F682746b153E289C87eACf99";
export const investmentContractAddress =
  "0x8084a52bC5De8e6339BF40918b1451250079867d";
export const priceContractAddress =
  "0xe9c859CE458D213e963a6D7273B138F3a2196f04";
export const pboxAddress = "0xf18792641892f3e21C95A12e6376Eaf0644f309d";
export const dbnAddress = "0x9Ec527ad405Af5f597E1205D8F7F0d699E04E23A";
export const NETWORK = {
  chainId: `0x4`,
};

// API URL//
export const BASE_URL = "http://api-alb-639668996.us-west-2.elb.amazonaws.com/";
