import React, { useEffect } from "react";
import style from "./Footer.module.scss";
import NewLogo from "../../../Assets/icons/footerLogo.svg";
import Facebook from "../../../Assets/icons/Facebook.svg";
import Twitter from "../../../Assets/icons/Twitter.svg";
import Instagram from "../../../Assets/icons/Instagram.svg";
import Linkedin from "../../../Assets/icons/Linkedin.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className={style.container}>
        <div className={style.wrapper}>
          <div className={style.leftsection}>
            <div className={style.logoimage}>
              <Link to="/#" onClick={() => window.location.replace("/#")}>
                <img src={NewLogo} alt="Logo" />
              </Link>
            </div>
            <div className={style.text}>
              <p className={style.innerpara}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam.
              </p>
            </div>
            <div className={style.socialIconsWrapper}>
              <ul className={style.socialicons}>
                <li>
                  <a href="#" rel="noreferrer" target="_blank">
                    <img
                      src={Linkedin}
                      className={style.iconsimage}
                      alt="Linkedin"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" rel="noreferrer" target="_blank">
                    <img
                      src={Facebook}
                      className={style.iconsimage}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href="#" rel="noreferrer" target="_blank">
                    <img src={Twitter} alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" rel="noreferrer" target="_blank">
                    <img src={Instagram} alt="Reddit" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={style.mainlistsection}>
            <div className={style.listsection}>
              <label>Useful Links</label>
              <ul>
                <li>
                  <Link to="/#" onClick={() => window.location.replace("/#")}>
                    <a href="_blank">Home</a>
                  </Link>
                </li>
                <li>
                  <Link to="/ecosystem">
                    <a href="_blank">Ecosystem</a>
                  </Link>
                </li>
                <li>
                  <Link to="/staking">
                    <a href="_blank">Staking</a>
                  </Link>
                </li>
                <li>
                  <Link to="/pandoras-box">
                    <a href="_blank">My PBOX</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={style.listsection}>
              <label>Company</label>
              <div className={style.colwrapper}>
                <ul>
                  <li>
                    <Link to="/">
                      <a href="_blank">About Us</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <a href="_blank">Contact Us</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.listsection}>
              <label>Resources</label>
              <ul>
                <li>
                  <Link to="/">
                    <a href="_black">White Paper</a>
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <a href="#" target="_blank">
                      Documentation
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={style.copyright}>
          <p>© 2022 Pandoras Box. Privacy Policy & Terms and Conditions. </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
