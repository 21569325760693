import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  metaMaskToken: null,
};

export const metaMaskTokenSlice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setMetaMaskToken(state, action) {
      state.metaMaskToken = action.payload;
      console.log(action.payload, "action.payload");
    },
  },
});

export const { setMetaMaskToken } = metaMaskTokenSlice.actions;
export default metaMaskTokenSlice.reducer;
