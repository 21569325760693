import React, { useState, useEffect, useRef } from "react";
import styles from "./dropDown.module.scss";
import dropdown from "../../../Assets/icons/dropdown.svg";

const DropDown = ({ grow, selected, setSelected, options }) => {
  const [isActive, setIsActive] = useState(false);
  const dropRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", onClickOutSide);
    return () => {
      document.removeEventListener("click", onClickOutSide);
    };
  }, []);
  const onClickOutSide = (event) => {
    const isClickInsideElement = dropRef?.current?.contains(event.target);
    if (!isClickInsideElement) {
      setIsActive(false);
    }
  };

  return (
    <div className={styles.dropdown} ref={dropRef}>
      <div
        className={styles.dropdownbtn}
        onClick={(e) => setIsActive(!isActive)}
      >
        <span>{selected}</span>
        <img src={dropdown} className={styles.dropDownimg} />
      </div>
      {isActive && (
        <div
          className={`${styles.dropdowncontent} ${
            grow && styles.dropdowncontentPaddingZero
          }`}
        >
          {options.map((option) => (
            <div
              onClick={(e) => {
                if (option !== selected) {
                  setSelected(option);
                  setIsActive(false);
                }
              }}
              className={`${styles.dropdownitems} ${
                option === selected && styles.dropdownitemsDisable
              }`}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
