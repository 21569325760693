const preSaleCardData = [
  {
    id: 1,
    title: "Level A",
    eth: "Initial Price in ETH",
    dnb: "Total DBN Received",
    day: "DBN Per Day",
    ethAmount: "4.5",
    dnbPrice: "45,000",
    days: "150",
    Buy: "Buy",
    Claim: "Claim",
  },
  {
    id: 2,
    title: "Level B",
    eth: "Initial Price in ETH",
    dnb: "Total DBN Received",
    day: "DBN Per Day",
    ethAmount: "1.8",
    dnbPrice: "18,000",
    days: "150",
    Buy: "Buy",
    Claim: "Claim",
  },
  {
    id: 3,
    title: "Level C",
    eth: "Initial Price in ETH",
    dnb: "Total DBN Received",
    day: "DBN Per Day",
    ethAmount: "0.9",
    dnbPrice: "9,000",
    days: "150",
    Buy: "Buy",
    Claim: "Claim",
  },
  {
    id: 4,
    title: "Level D",
    eth: "Initial Price in ETH",
    dnb: "Total DBN Received",
    day: "DBN Per Day",
    ethAmount: "0.45",
    dnbPrice: "4,500",
    days: "150",
    Buy: "Buy",
    Claim: "Claim",
  },
  {
    id: 5,
    title: "Level E",
    eth: "Initial Price in ETH",
    dnb: "Total DBN Received",
    day: "DBN Per Day",
    ethAmount: "0.18",
    dnbPrice: "1,800",
    days: "150",
    Buy: "Buy",
    Claim: "Claim",
  },
];
export default preSaleCardData;
