import { HTTP_CLIENT } from "../utils/request";
import { URL } from "../utils/constants/url.contants";
import axios from "axios";

export const submituserReview = async (data) => {
  console.log(URL.SUBMIT_USER_REVIEW);
  return HTTP_CLIENT.post(URL.SUBMIT_USER_REVIEW, data);
};
export const getUserReview = () => {
  return HTTP_CLIENT.get(URL.GET_USER_REVIEW);
};
