import React, { useState, useEffect } from "react";
import styles from "./swap.module.scss";
import DropDown from "../__common/DropDown/dropDown";
import { getConvertedToken } from "../../Actions/PreSaleActions";
import { useWeb3React } from "@web3-react/core";
import {
  preSaleContractAddress,
  pboxAddress,
  dbnAddress,
} from "../../utils/constants/app.constants";
import {
  getPboxApprovall,
  checkPboxAllowance,
  getdynamicpboxBalance,
} from "../../Actions/PboxActions";

import {
  getdbnApprovall,
  checkDbnAllowance,
  getdynamicdbnBalance,
} from "../../Actions/DbnActions";

import { useAlert } from "react-alert";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";

const SwapComp = () => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const alert = useAlert();
  const [totalValue, setTotalValue] = useState({});
  const options = ["DBN", "PBOX"];
  const [selected, setSelected] = useState(options[0]);
  const [amount, setAmount] = useState("");
  const [dbnTokenBalance, setDbnTokenBalance] = useState(0);
  const [pboxTokenBalance, setPboxTokenBalance] = useState(0);
  const [preSaleAddress, setPreSaleAddress] = useState(preSaleContractAddress);
  const [loading, setLoading] = useState(false);

  console.log(dbnTokenBalance, "dbnTokenBalance");
  const clearFields = () => {
    setAmount("");
  };
  //dynamic balance getting//
  useEffect(() => {
    if (metaMaskToken) {
      fetchBalanceOfDbnToken(metaMaskToken);
      fetchBalanceOfPboxToken(metaMaskToken);
    }
  }, [metaMaskToken, selected]);

  const fetchBalanceOfDbnToken = async () => {
    const dbnBlnc = await getdynamicdbnBalance(metaMaskToken);
    setDbnTokenBalance(dbnBlnc);
  };

  const fetchBalanceOfPboxToken = async () => {
    const pboxBalnc = await getdynamicpboxBalance(metaMaskToken);
    setPboxTokenBalance(pboxBalnc);
  };

  //converting entered amount into wei//
  let enteredAmount = Number(amount) * Math.pow(10, 18);
  //pbox token approval
  const pboxApproval = async () => {
    debugger;
    let etherValue = "187263899288902890000000000000000000000000000000000";
    let address = preSaleAddress;
    // const etherValue = Web3.utils.toWei(wei, "ether");
    const response = await getPboxApprovall(address, etherValue, metaMaskToken);
    console.log("pbox approval", response);
  };

  //dbn token approval//
  const dbnApproval = async () => {
    debugger;
    let etherValue = "187263899288902890000000000000000000000000000000000";
    let address = preSaleAddress;
    // const etherValue = Web3.utils.toWei(wei, "ether");
    const response = await getdbnApprovall(address, etherValue, metaMaskToken);
    console.log("dbn approval", response);
  };

  //passing calculated values to swapping //
  const calculateAmount = async () => {
    if (selected === "DBN") {
      const res = await getConvertedToken(
        {
          from: dbnAddress,
          to: pboxAddress,
        },
        amount,
        metaMaskToken
      );
      setTotalValue({
        from: "DBN",
        to: "PBOX",
        amount: res,
      });
    } else if (selected === "PBOX") {
      const res = await getConvertedToken(
        {
          from: pboxAddress,
          to: dbnAddress,
        },
        amount,
        metaMaskToken
      );
      setTotalValue({
        from: "PBOX",
        to: "DBN",
        amount: res,
      });
    }
  };
  const handleSwapping = async () => {
    debugger;
    if (metaMaskToken) {
      if (amount === "") {
        alert.show("Enter Token Amount to Swap", { type: "error" });
      } else {
        //   //swapping from pbox to dbn//
        if (selected === "PBOX") {
          setLoading(true);
          const validatePboxAllowance = await checkPboxAllowance(
            metaMaskToken,
            preSaleAddress
          );
          if (validatePboxAllowance == 0) {
            try {
              setLoading(true);
              const approvedPbox = await pboxApproval();
              clearFields();
              setLoading(false);
              alert.show("Successfully Approved! You can Perform swap", {
                type: "success",
              });
            } catch (error) {
              alert.show("There's an error", { type: "error" });
              setLoading(false);
            }
          } else if (validatePboxAllowance < enteredAmount) {
            try {
              setLoading(true);
              const approvedPbox = await pboxApproval();
              setLoading(false);
              clearFields();
              alert.show("Successfully Approved! You can Perform swap", {
                type: "success",
              });
            } catch (error) {
              alert.show("Transaction not performed ", { type: "error" });
              setLoading(false);
            }
          } else {
            try {
              setLoading(true);
              const calAmount = await calculateAmount();
              console.log("calAmount", calAmount);
              setLoading(false);
              clearFields();
              // await fetchBalanceOfPboxToken();

              alert.show("Swapped Successfully ", { type: "success" });
            } catch (error) {
              alert.show("Transaction not performed ", { type: "error" });
              setLoading(false);
            }
          }
        }
        //swapping from dbn to pbox//
        else if (selected === "DBN") {
          setLoading(true);
          const validatePboxAllowance = await checkDbnAllowance(
            metaMaskToken,
            preSaleAddress
          );
          if (validatePboxAllowance == 0) {
            try {
              setLoading(true);
              const approvedPbox = await dbnApproval();
              clearFields();
              setLoading(false);
              alert.show("Successfully Approved! You can Perform swap", {
                type: "success",
              });
            } catch (error) {
              alert.show("Token Not Approved", { type: "error" });
              setLoading(false);
            }
          } else if (validatePboxAllowance < enteredAmount) {
            try {
              setLoading(true);
              const approvedPbox = await dbnApproval();
              alert.show("Successfully Approved! You can Perform swap", {
                type: "success",
              });
              clearFields();
              setLoading(false);
            } catch (error) {
              alert.show("Token Not Approved ", { type: "error" });
              setLoading(false);
            }
          } else {
            try {
              setLoading(true);
              const calAmount = await calculateAmount();
              clearFields();
              setLoading(false);
              // await fetchBalanceOfDbnToken();
              alert.show("Swapped Successfully ", { type: "success" });
            } catch (error) {
              alert.show("Transaction Not Performed ", { type: "error" });
              setLoading(false);
            }
          }
        }
      }
    } else {
      alert.show("Connect Your wallet", { type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.ContentContainer}>
          <div className={styles.stakeWrapper}>
            <div className={styles.stakeContent}>
              <div className={styles.stake}>
                <div className={styles.heading}>Swap</div>
                <div className={styles.balanceText}>
                  <span>
                    {` Balance: ${
                      selected === "PBOX" ? pboxTokenBalance : dbnTokenBalance
                    } `}
                  </span>
                </div>
                <div className={styles.inputWrapper}>
                  <div className={styles.claimInput}>
                    <DropDown
                      grow="100%"
                      selected={selected}
                      setSelected={setSelected}
                      options={options}
                    />
                    <input
                      type="text"
                      placeholder="0.00"
                      name="WEI"
                      className={styles.inputs}
                      value={amount}
                      onChange={(event) => {
                        setAmount(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className={styles.conversionTextWrapper}>
                  <div className={styles.bottomtext2}>
                    {`Total ${
                      selected === "DBN" ? "PBOX" : "DBN"
                    }  you will get : ${amount ? amount : 0.0}`}
                  </div>
                </div>
                <div className={styles.approveButton} onClick={handleSwapping}>
                  {loading ? (
                    <ThreeDots
                      height="30"
                      width="30"
                      color="white"
                      ariaLabel="loading"
                    />
                  ) : (
                    " Swap"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapComp;
