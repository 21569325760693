const faqData = [
  {
    id: 1,
    question: "How Does Sins Work?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
  {
    id: 2,
    question: "What problem is Sins trying to solve?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
  {
    id: 3,
    question: "Where can I buy a Sins node?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
  {
    id: 4,
    question: "How does a node work?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
  {
    id: 5,
    question: "Where do the node rewards come from?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
  {
    id: 6,
    question: "Can I sell my node?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
  {
    id: 7,
    question: "If I don’t claim my rewards on time, will I lose them?",
    answer:
      "Creating a sustainable wealth creation protocol, that can provide long term passive income for its holders. Through our number of sustainability measures, such as our dynamic transaction tax system and the 7 deadly Sins we believe we have created the best formula thus far!",
  },
];

export default faqData;
