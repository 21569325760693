import React, { useEffect, useState } from "react";
import styles from "./ComingSoon.module.scss";
import comingsoon from "../../../Assets/images/comingsoon.png";
import { buyLevel } from "../../../Actions/PreSaleActions";

const ComingSoon = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.mainHeading}>Coming soon</div>
          <img src={comingsoon} alt="comingsoon" className={styles.img} />
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
