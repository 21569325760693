import React from "react";
import Home from "./Pages/Home/Home";
import Ecosystem from "./Pages/Ecosystem/Ecosystem";
import Staking from "./Pages/Staking/Staking";
import BBX from "./Pages/BBX/BBX";
import PreSale from "./Pages/PreSale/Presale";
import Swap from "./Pages/Swap/Swap";
import styles from "./index.module.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { render } from "react-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Nav from "./components/__common/Header/Nav/Nav";
import Footer from "./components/__common/Footer/Footer";
import ScrollToTop from "./components/__common/scrollToTop";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import AlertTemplate from "./components/__common/Alert/AlertTemplate";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  function getLibrary(provider) {
    return new Web3(provider);
  }
  const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 5000,
    offset: "10px",
    transition: transitions.SCALE,
  };
  return (
    <>
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <AlertProvider template={AlertTemplate} {...options}>
              <BrowserRouter>
                <ScrollToTop />
                <Nav />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/ecosystem" element={<Ecosystem />} />
                  <Route path="/staking" element={<Staking />} />
                  <Route path="/pandoras-box" element={<BBX />} />
                  <Route path="/pre-sale" element={<PreSale />} />
                  <Route path="/swap" element={<Swap />} />
                </Routes>
                <Footer />
              </BrowserRouter>
            </AlertProvider>
          </Web3ReactProvider>
        </div>
      </div>
    </>
  );
};

export default App;
