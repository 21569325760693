import React, { useState, useEffect } from "react";
import styles from "./preSaleCard.module.scss";
import { Link } from "react-router-dom";
import preSaleCardData from "./preSaleCardData";
import {
  buyLevel,
  claimLevel,
  claimAllLevel,
  dynamicSupply,
  purchase,
  claimable,
} from "../../../Actions/PreSaleActions";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";

const PresaleCard = () => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const alert = useAlert();
  const [cardData, setCardData] = useState(preSaleCardData);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [clickedLevelId, setClickedLevelId] = useState(0);
  const [preSaleTokens, setPreSaleTokens] = useState(null);

  let checkLastClaimTimePurchasing;
  useEffect(() => {
    fetchPreSaleToken();
  });

  //dynamic supply used in heading//
  const fetchPreSaleToken = async () => {
    const token = await dynamicSupply();
    setPreSaleTokens(token);
  };

  // buy Protocol//
  const buyProtocol = async (level) => {
    if (metaMaskToken) {
      setLoading(true);
      const userPurchased = await purchase(metaMaskToken, level);
      checkLastClaimTimePurchasing = userPurchased.lastClaimTime;
    }
    if (!metaMaskToken) {
      alert.show("Connect Your Wallet ", { type: "error" });
      setLoading(false);
    } else if (checkLastClaimTimePurchasing == 0) {
      try {
        setLoading(true);
        const Purchased = await buyLevel(level, metaMaskToken);

        alert.show("Level purchased successfully", { type: "success" });
        setLoading(false);
      } catch (error) {
        alert.show("Transaction not performed ", { type: "error" });
        setLoading(false);
      }
    } else if (checkLastClaimTimePurchasing !== 0) {
      alert.show("You can't buy Level again", { type: "error" });
      setLoading(false);
    }
  };
  //claim Protocol//
  const claimProtocol = async (level) => {
    let checkLastClaimTime;
    let Difference_In_Time;
    if (metaMaskToken) {
      const userPurchased = await purchase(metaMaskToken, level);
      checkLastClaimTime = userPurchased.lastClaimTime;
      // calculate epoch time//
      let currentTime = moment().unix();
      Difference_In_Time = currentTime - checkLastClaimTime;
    }
    if (!metaMaskToken) {
      alert.show("Connect Your Wallet ", { type: "error" });
    } else if (Difference_In_Time > 600) {
      try {
        setIsLoading(true);
        if (checkLastClaimTime == 0) {
          alert.show("You must have to buy Level to claim", {
            type: "error",
          });
          setIsLoading(false);
        } else {
          setIsLoading(true);
          const claim = await claimLevel(level, metaMaskToken);
          alert.show("Successfully claimed", { type: "success" });
          setIsLoading(false);
        }
      } catch (error) {
        alert.show("There's an error", { type: "error" });
        setIsLoading(false);
      }
    } else {
      alert.show("Please Claim After 10 Minutes", { type: "error" });
      setIsLoading(false);
    }
  };
  //claim all Protocol//
  const claimAllProtocol = async () => {
    let claim;
    if (metaMaskToken) {
      claim = await claimable(metaMaskToken);
    }
    if (!metaMaskToken) {
      alert.show("Connect Your Wallet ", { type: "error" });
    }
    if (claim == 0) {
      alert.show("There's nothing to claim", { type: "error" });
    } else {
      try {
        setSpinning(true);
        const claimAll = await claimAllLevel(metaMaskToken);
        alert.show("Successfully claimed all levels", { type: "success" });
        setSpinning(false);
      } catch (error) {
        alert.show("There's an error", { type: "error" });
        setSpinning(false);
      }
    }
  };
  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.ContentContainer}>
          <div className={styles.textWrapper}>
            Total Supply For Presale {preSaleTokens ? preSaleTokens : 0} Tokens
          </div>
          <div className={styles.cardWrapper}>
            {cardData?.map((item) => {
              const {
                id,
                title,
                eth,
                dnb,
                day,
                ethAmount,
                dnbPrice,
                days,
                Buy,
                Claim,
              } = item;
              return (
                <div>
                  <div className={styles.card} key={id}>
                    <div>
                      <div className={styles.header}>
                        <label>{title}</label>
                      </div>
                      <div className={styles.description}>
                        <div className={styles.row}>
                          <div className={styles.left}>{eth}</div>
                          <div className={styles.right}>{ethAmount}</div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.left}>{dnb}</div>
                          <div className={styles.right}>{dnbPrice}</div>
                        </div>
                        <div className={styles.row}>
                          <div className={styles.left}>{day}</div>
                          <div className={styles.right}>{days}</div>
                        </div>
                      </div>
                      <div className={styles.btnRow}>
                        <button
                          className={styles.buyBtn}
                          disabled={loading}
                          onClick={() => {
                            buyProtocol(id);
                            setClickedLevelId(id);
                          }}
                        >
                          {clickedLevelId == id && loading ? (
                            <ThreeDots
                              height="30"
                              width="30"
                              color="white"
                              ariaLabel="loading"
                            />
                          ) : (
                            "Buy"
                          )}
                        </button>
                        <button
                          className={styles.claimBtn}
                          onClick={() => {
                            claimProtocol(id);
                            setClickedLevelId(id);
                          }}
                          disabled={isLoading}
                        >
                          {clickedLevelId == id && isLoading ? (
                            <ThreeDots
                              height="30"
                              width="30"
                              color="black"
                              ariaLabel="loading"
                              marginBottom="10px"
                            />
                          ) : (
                            "Claim"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.claimAllWrapper}>
            <button
              className={styles.claimAllBtn}
              onClick={claimAllProtocol}
              disabled={loading}
            >
              {spinning ? (
                <ThreeDots
                  height="30"
                  width="30"
                  color="white"
                  ariaLabel="loading"
                  marginBottom="10px"
                />
              ) : (
                " Claim All"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresaleCard;
