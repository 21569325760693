import React, { useState, useEffect } from "react";
import styles from "./ReviewModal.module.scss";
import ModalLayout from "../../../__common/ReviewModalLayout/ModalLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ReviewSchema } from "./ReviewSchema";
import { ThreeDots } from "react-loader-spinner";
import {
  submituserReview,
  getUserReview,
} from "../../../../services/user.service";
import { useAlert } from "react-alert";
import required from "../../../../Assets/icons/requiredIcon.svg";

const ReviewModal = ({ isOpen, toggle }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    designation: "",
    description: "",
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const review = await submituserReview({
        ...values,
        fullName: values.name,
        email: values.email,
        designation: values.designation,
        description: values.description,
      });
      toggle();
      alert.show("Review Submitted Successfully ", { type: "success" });
      setLoading(false);
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <ModalLayout isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainheading}>Submit Your Review</div>
        <Formik
          initialValues={initialValues}
          validationSchema={ReviewSchema}
          onSubmit={handleSubmit}
        >
          <Form className={styles.form}>
            <div className={styles.InputWrapper}>
              <div style={{ display: "flex" }}>
                <img
                  src={required}
                  style={{ width: "8px", marginRight: "10px" }}
                />
                <label>Full Name</label>
              </div>
              <div className={styles.inputDiv}>
                <Field type="text" name="name" />
              </div>
              <ErrorMessage name="name">
                {(errorText) => <div className={styles.error}>{errorText}</div>}
              </ErrorMessage>
            </div>
            <div className={styles.InputWrapper}>
              <div style={{ display: "flex" }}>
                <img
                  src={required}
                  style={{ width: "8px", marginRight: "10px" }}
                />
                <label>Email</label>
              </div>

              <div className={styles.inputDiv}>
                <Field type="text" name="email" />
              </div>
              <ErrorMessage name="email">
                {(errorText) => <div className={styles.error}>{errorText}</div>}
              </ErrorMessage>
            </div>
            <div className={styles.InputWrapper}>
              <div style={{ display: "flex" }}>
                <img
                  src={required}
                  style={{ width: "8px", marginRight: "10px" }}
                />
                <label>Designation</label>
              </div>

              <div className={styles.inputDiv}>
                <Field type="text" name="designation" />
              </div>
              <ErrorMessage name="designation">
                {(errorText) => <div className={styles.error}>{errorText}</div>}
              </ErrorMessage>
            </div>
            <div className={styles.InputWrapper}>
              <div style={{ display: "flex" }}>
                <img
                  src={required}
                  style={{ width: "8px", marginRight: "10px" }}
                />
                <label>Description</label>
              </div>

              <Field
                type="text"
                name="description"
                as="textarea"
                className={styles.textArea}
              />
              <ErrorMessage name="description">
                {(errorText) => <div className={styles.error}>{errorText}</div>}
              </ErrorMessage>
            </div>
            <div className={styles.btnWrapper}>
              <button className={styles.button} disabled={loading}>
                {loading ? (
                  <ThreeDots
                    height="30"
                    width="30"
                    color="black"
                    ariaLabel="loading"
                  />
                ) : (
                  " Submit"
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </ModalLayout>
    </>
  );
};

export default ReviewModal;
