import { createSlice } from "@reduxjs/toolkit";
import { userData } from "../../Actions/InvestmentActions";
import { estimatedEth } from "../../Actions/PriceActons";

const initialState = {
  mypboxData: [],
  protocolData: [],
  load: true,
  metaMaskToken: null,
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setMypboxData(state, action) {
      state.mypboxData = action.payload;
    },
    setProtocolData(state, action) {
      state.protocolData = action.payload;
    },
    setLoad(state, action) {
      state.load = action.payload;
      console.log(action.payload, "action.payload");
    },
    setMetaMaskToken(state, action) {
      state.metaMaskToken = action.payload;
      console.log(action.payload, "action.payload");
    },
  },
});

//thunk//
export const levelChecker = (account) => async (dispatch) => {
  try {
    let pboxData = [];
    const levelNames = ["Level A", "Level B", "Level C", "Level D", "Level E"];
    const levelAmounts = [
      "7500 DBN",
      "3600 DBN",
      "2000 DBN",
      "1300 DBN",
      "600 DBN",
    ];
    const levels = await userData(account);

    for (let index = 0; index < levels?.length; index++) {
      const level = levels[index];
      if (level !== "0") {
        pboxData.push({
          id: index + 1,
          level: levelNames[index],
          dbn: levelAmounts[index],
        });
      }
    }
    dispatch(setMypboxData(pboxData));
  } catch (error) {
    console.log(error.response.data);
  }
};

export const transformCardData = (cardData) => async (dispatch) => {
  try {
    dispatch(setLoad(true));
    let cardDataTransformed = [];
    for (let card of cardData) {
      const dbnReceivedBitInt = card?.tokenCost * Math.pow(10, 18);
      const dbnConverted = dbnReceivedBitInt.toLocaleString("fullwide", {
        useGrouping: false,
      });
      const estimatedEther = await estimatedEth(dbnConverted);
      cardDataTransformed.push({
        ...card,
        dbnConverted,
        estimatedEth: estimatedEther,
      });
    }
    dispatch(setProtocolData(cardDataTransformed));
    dispatch(setLoad(false));
  } catch (error) {
    console.log(error.response.data);
    dispatch(setLoad(false));
  }
};

export const { setMypboxData, setProtocolData, setLoad } =
  contractSlice.actions;
export default contractSlice.reducer;
