export const LevelAData = [
  {
    id: 1,
    level: "Level A",
    bbx: "15,000 PBOX",
    rio: "1.5%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 2,
    level: "Level A",
    bbx: "15,000 PBOX",
    rio: "1.5%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 3,
    level: "Level A",
    bbx: "15,000 PBOX",
    rio: "1.5%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 4,
    level: "Level A",
    bbx: "15,000 PBOX",
    rio: "1.5%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 5,
    level: "Level A",
    bbx: "15,000 PBOX",
    rio: "1.5%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
];
export const LevelBData = [
  {
    id: 1,
    level: "Level B",
    bbx: "75,000 PBOX",
    rio: "1.43%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 2,
    level: "Level B",
    bbx: "75,000 PBOX",
    rio: "1.43%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 3,
    level: "Level B",
    bbx: "75,000 PBOX",
    rio: "1.43%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 4,
    level: "Level B",
    bbx: "75,000 PBOX",
    rio: "1.43%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 5,
    level: "Level B",
    bbx: "75,000 PBOX",
    rio: "1.43%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
];
export const LevelCData = [
  {
    id: 1,
    level: "Level C",
    bbx: "4,000 PBOX",
    rio: "1.36%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 2,
    level: "Level C",
    bbx: "4,000 PBOX",
    rio: "1.36%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 3,
    level: "Level C",
    bbx: "4,000 PBOX",
    rio: "1.36%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 4,
    level: "Level C",
    bbx: "4,000 PBOX",
    rio: "1.36%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 5,
    level: "Level C",
    bbx: "4,000 PBOX",
    rio: "1.36%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
];
export const LevelDData = [
  {
    id: 1,
    level: "Level D",
    bbx: "15,000 PBOX",
    rio: "1.30%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 2,
    level: "Level D",
    bbx: "15,000 PBOX",
    rio: "1.30%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 3,
    level: "Level D",
    bbx: "15,000 PBOX",
    rio: "1.30%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 4,
    level: "Level D",
    bbx: "15,000 PBOX",
    rio: "1.30%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 5,
    level: "Level D",
    bbx: "15,000 PBOX",
    rio: "1.30%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
];

export const LevelEData = [
  {
    id: 1,
    level: "Level E",
    bbx: "750 PBOX",
    rio: "1.25%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 2,
    level: "Level E",
    bbx: "750 PBOX",
    rio: "1.25%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 3,
    level: "Level E",
    bbx: "750 PBOX",
    rio: "1.25%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 4,
    level: "Level E",
    bbx: "750 PBOX",
    rio: "1.25%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
  {
    id: 5,
    level: "Level E",
    bbx: "750 PBOX",
    rio: "1.25%",
    warmPeriod: "100 day Left",
    action: "Claim",
  },
];

export const BBXData = [
  {
    id: 1,
    level: "Level A",
    bbx: "7500 PBOX",
    action: "Claim",
  },
  {
    id: 2,
    level: "Level B",
    bbx: "3600 PBOX",
    action: "Claim",
  },
  {
    id: 3,
    level: "Level C",
    bbx: "2000 PBOX",
    action: "Claim",
  },
  {
    id: 4,
    level: "Level D",
    bbx: "1300 PBOX",
    action: "Claim",
  },
  {
    id: 5,
    level: "Level E",
    bbx: "600 PBOX",
    action: "Claim",
  },
];
