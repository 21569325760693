import React, { useState } from "react";
import styles from "./StakingUnstaking.module.scss";
import staking from "../../../Assets/images/staking.png";
import noteIcon from "../../../Assets/icons/noteIcon.svg";
import { useSelector } from "react-redux";
import { buyLevel, purchase } from "../../../Actions/InvestmentActions";
import { useAlert } from "react-alert";
import { ThreeDots } from "react-loader-spinner";

const StakingUnstaking = ({ selectedCardData }) => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const alert = useAlert();
  const [active, setActive] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  //buy protocol//
  const buyProtocol = async (level) => {
    let checkLastClaimTime;
    if (selectedCardData.dbnAmount === undefined) {
      alert.show("Select Any One Level Of The Above Protocols ", {
        type: "error",
      });
    } else {
      if (metaMaskToken) {
        setIsLoading(true);
        const userPurchased = await purchase(metaMaskToken, level);
        checkLastClaimTime = userPurchased.lastClaimTime;
      }
      if (!metaMaskToken) {
        alert.show("Connect Your Wallet ", { type: "error" });
        setIsLoading(false);
      } else if (checkLastClaimTime == 0) {
        try {
          setIsLoading(true);
          const Protocol = await buyLevel(level, metaMaskToken);
          alert.show("Level purchased successfully", { type: "success" });
          setIsLoading(false);
        } catch (error) {
          alert.show("Transaction not performed ", { type: "error" });
          setIsLoading(false);
        }
      } else if (checkLastClaimTime !== 0) {
        alert.show("You can't buy Level again", { type: "error" });
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.ContentContainer}>
          <div className={styles.stakeWrapper}>
            <div className={styles.header}>
              <ul>
                <li
                  onClick={() => setActive(1)}
                  className={`${styles.navItem} ${
                    active === 1 ? styles.active : ""
                  }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Stake
                </li>
                {/* <li
                  onClick={() => setActive(2)}
                  className={`${styles.navItem} ${
                    active === 2 ? styles.active : ""
                  }`}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Claim
                </li>*/}
              </ul>
            </div>

            {active === 1 && (
              <div className={styles.stakeContent}>
                <div className={styles.stake}>
                  {/*<div className={styles.balanceText}>
                    <span>Balance: 0.00</span>
            </div>*/}
                  <div className={styles.selectProtocol}>
                    <img src={noteIcon} />
                    <span>Select Any One Level Of The Above Protocols</span>
                  </div>
                  <div className={styles.inputDiv}>
                    <input
                      type="text"
                      placeholder={selectedCardData.recievedDBN || "Balance"}
                      name="amount"
                      readOnly={true}
                    />
                  </div>
                  <div
                    className={styles.approveButton}
                    onClick={() => buyProtocol(selectedCardData.id)}
                  >
                    {isLoading ? (
                      <ThreeDots
                        height="30"
                        width="30"
                        color="white"
                        ariaLabel="loading"
                        marginBottom="10px"
                      />
                    ) : (
                      "Buy"
                    )}
                  </div>
                  {/* <div className={styles.stakeButton}>Stake</div>*/}
                </div>

                <div className={styles.amountWrapper}>
                  <div className={styles.row}>
                    <div className={styles.left}>DBN Per Day</div>
                    <div className={styles.right}>
                      {selectedCardData.dbnAmount || "0.00"}
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.left}>Total Received</div>
                    <div className={styles.right}>
                      {selectedCardData.recievedDBN || "0.00"}
                    </div>
                  </div>
                  {/*     <div className={styles.row}>
                    <div className={styles.left}>Reward</div>
                    <div className={styles.right}>
                      {selectedCardData.roiPercent || "0.00"}
                    </div>
          </div>*/}
                </div>
              </div>
            )}

            {active === 2 && (
              <>
                <div className={styles.claimWrapper}>
                  <div className={styles.imgWrapper}>
                    <img src={staking} alt="staking" />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.text}>You will get up to</div>
                    <div className={styles.amount}>0</div>
                    <div className={styles.bbx}>PBOX</div>
                  </div>
                  <div className={styles.stakedBalance}>
                    <div className={styles.stakeContent}>
                      Staked Balance: 0.00
                    </div>
                  </div>
                  <div className={styles.inputWrapper}>
                    <div className={styles.claimInput}>
                      <input
                        type="number"
                        placeholder="Enter Amount"
                        name="WEI"
                        className={styles.inputs}
                      />
                      <div className={styles.btn}>MAX</div>
                    </div>
                  </div>
                  <div className={styles.claimWrapper}>
                    <div className={styles.claimButton}>Claim</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StakingUnstaking;
