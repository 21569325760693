import React, { useState } from "react";
import PresaleCard from "../../components/PreSaleModules/PresaleCard/PresaleCrad";

const PreSale = () => {
  return (
    <>
      <PresaleCard />
    </>
  );
};

export default PreSale;
