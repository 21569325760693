import * as yup from "yup";

export const ReviewSchema = yup.object({
  name: yup.string().required("Required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is required"),
  designation: yup.string().required("Required"),
  description: yup.string().required("Required"),
});
