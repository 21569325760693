import React, { useState } from "react";
import styles from "./myBbx.module.scss";
import {
  LevelEData,
  LevelDData,
  LevelCData,
  LevelBData,
  LevelAData,
} from "./BBXData";

const AllTabels = ({ level }) => {
  const [levelaData, setlevelaData] = useState(LevelAData);
  const [LevelbData, setLevelbData] = useState(LevelBData);
  const [LevelcData, setLevelcData] = useState(LevelCData);
  const [LeveldData, setLeveldData] = useState(LevelDData);
  const [LeveleData, setLeveleData] = useState(LevelEData);
  return (
    <>
      {level === "A" && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.label}>My Pandoras Box</div>
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Protocols</td>
                <td>P Box</td>
                <td>ROI</td>
                <td>Warm Period</td>
                <td>Action</td>
              </tr>
              {levelaData.map((currElem) => {
                const { id, level, bbx, rio, warmPeriod, action } = currElem;
                return (
                  <tr key={id}>
                    <td>
                      <div className={styles.level}>{level}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{bbx}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{rio}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{warmPeriod}</div>
                    </td>
                    <td>
                      <div className={styles.action}>{action}</div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
      {/* level B data Tabel*/}
      {level === "B" && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.label}>My Pandoras Box</div>
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Protocols</td>
                <td>P Box</td>
                <td>ROI</td>
                <td>Warm Period</td>
                <td>Action</td>
              </tr>
              {LevelbData.map((currElem) => {
                const { id, level, bbx, rio, warmPeriod, action } = currElem;
                return (
                  <tr key={id}>
                    <td>
                      <div className={styles.level}>{level}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{bbx}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{rio}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{warmPeriod}</div>
                    </td>
                    <td>
                      <div className={styles.action}>{action}</div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
      {/* level C data Tabel*/}
      {level === "C" && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.label}>My Pandoras Box</div>
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Protocols</td>
                <td>P Box</td>
                <td>ROI</td>
                <td>Warm Period</td>
                <td>Action</td>
              </tr>
              {LevelcData.map((currElem) => {
                const { id, level, bbx, rio, warmPeriod, action } = currElem;
                return (
                  <tr key={id}>
                    <td>
                      <div className={styles.level}>{level}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{bbx}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{rio}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{warmPeriod}</div>
                    </td>
                    <td>
                      <div className={styles.action}>{action}</div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
      {/* level D data Tabel*/}
      {level === "D" && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.label}>My Pandoras Box</div>
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Protocols</td>
                <td>P Box</td>
                <td>ROI</td>
                <td>Warm Period</td>
                <td>Action</td>
              </tr>
              {LeveldData.map((currElem) => {
                const { id, level, bbx, rio, warmPeriod, action } = currElem;
                return (
                  <tr key={id}>
                    <td>
                      <div className={styles.level}>{level}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{bbx}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{rio}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{warmPeriod}</div>
                    </td>
                    <td>
                      <div className={styles.action}>{action}</div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
      {/* level E data Tabel*/}
      {level === "E" && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.label}>My Pandoras Box</div>
            <table cellspacing="0" cellpadding="0">
              <tr>
                <td>Protocols</td>
                <td>P Box</td>
                <td>ROI</td>
                <td>Warm Period</td>
                <td>Action</td>
              </tr>
              {LeveleData.map((currElem) => {
                const { id, level, bbx, rio, warmPeriod, action } = currElem;
                return (
                  <tr key={id}>
                    <td>
                      <div className={styles.level}>{level}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{bbx}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{rio}</div>
                    </td>
                    <td>
                      <div className={styles.level}>{warmPeriod}</div>
                    </td>
                    <td>
                      <div className={styles.action}>{action}</div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default AllTabels;
