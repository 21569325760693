import dbnContract from "../utils/dbnContract";
import Web3 from "web3";

//write functions//

export const getdbnApprovall = async (address, amount, account) => {
  console.log("preSaleAddress for DBN>>>>>>>", address);
  console.log("amount for DBN>>>>>>>", amount);
  let response = await dbnContract.methods
    .approve(address, amount)
    .send({ from: account });
  return response;
};
// read functions//
export const checkDbnAllowance = async (account, address) => {
  let response = await dbnContract.methods.allowance(account, address).call();
  return response;
};
export const getdynamicdbnBalance = async (account) => {
  console.log(account, "account");
  let response = await dbnContract.methods.balanceOf(account).call();
  const etherValue = Web3.utils.fromWei(response, "ether");
  return etherValue;
};
