const stakingCardData = [
  {
    id: 1,
    title: "Level A",
    dbn: "DBN per day",
    recieved: "Total DBN Received",
    dbnAmount: "75",
    recievedDBN: 7500,
    tokenCost: 5500,
  },
  {
    id: 2,
    title: "Level B",
    dbn: "DBN per day",
    recieved: "Total DBN Received",
    dbnAmount: "36",
    recievedDBN: 3600,
    tokenCost: 2750,
  },
  {
    id: 3,
    title: "Level C",
    dbn: "DBN per day",
    recieved: "Total DBN Received",
    dbnAmount: "20",
    recievedDBN: 2000,
    tokenCost: 1650,
  },
  {
    id: 4,
    title: "Level D",
    dbn: "DBN per day",
    recieved: "Total DBN Received",
    dbnAmount: "13",
    recievedDBN: 1300,
    tokenCost: 1100,
  },
  {
    id: 5,
    title: "Level E",
    dbn: "DBN per day",
    recieved: "Total DBN Received",
    dbnAmount: "6 ",
    recievedDBN: 600,
    tokenCost: 550,
  },
];
export default stakingCardData;
