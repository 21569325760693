import React, { useState, useEffect } from "react";
import styles from "./Testimonal.module.scss";
import Prev1 from "../../../Assets/icons/prev1.svg";
import Next2 from "../../../Assets/icons/next2.svg";
import Prev2 from "../../../Assets/icons/prev2.svg";
import Next1 from "../../../Assets/icons/next1.svg";
import testimonal from "../../../Assets/icons/Testimonal.svg";
import LongArrow from "../../../Assets/icons/longArrow.svg";
import ReviewModal from "./ReviewModal/ReviewModal";
import { getUserReview } from "../../../services/user.service";

const Testimonal = () => {
  const [slide, setSlide] = useState(1);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);
  const [usersReviews, setUsersReviews] = useState(false);
  const [active, setActive] = useState(false);
  const toggleReviewModal = () => {
    setIsOpenReviewModal(!isOpenReviewModal);
  };
  const handleLI = (num) => {
    const res = num;
    if (res > 4) {
      setSlide(1);
    } else {
      setSlide(res);
    }
  };

  useEffect(() => {
    fetchUserReviews();
  }, []);
  //fetch user reviews//
  const fetchUserReviews = async () => {
    const userReviews = await getUserReview();
    setUsersReviews(userReviews.data);
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.Container}>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>What People Say About US</div>
          </div>
          <div className={styles.upperWrapper}>
            <div className={styles.testimonalWrapper}>
              {slide === 1 && (
                <div
                  className={styles.testimonalCard}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.left}>
                    <div className={styles.Text1}>Claes Pellvik</div>
                    <div className={styles.Text2}>Founder and CEO</div>
                  </div>
                  <div className={styles.right}>
                    <img src={testimonal} alt="testimonal" />
                    <div className={styles.para}>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat.
                    </div>
                    <div className={styles.buttonsWrapper}>
                      <div
                        className={styles.button}
                        onClick={toggleReviewModal}
                      >
                        {/*<div className={styles.effect}></div>*/}
                        <span>Submit Your Review</span>
                        <img src={LongArrow} alt="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {slide === 2 && (
                <div
                  className={styles.testimonalCard}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.left}>
                    <div className={styles.Text1}> Pellvik</div>
                    <div className={styles.Text2}>Founder and CEO</div>
                  </div>
                  <div className={styles.right}>
                    <img src={testimonal} alt="testimonal" />
                    <div className={styles.para}>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat.
                    </div>
                    <div className={styles.buttonsWrapper}>
                      <div
                        className={styles.button}
                        onClick={toggleReviewModal}
                      >
                        {/*<div className={styles.effect}></div>*/}
                        <span>Submit Your Review</span>
                        <img src={LongArrow} alt="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {slide === 3 && (
                <div
                  className={styles.testimonalCard}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.left}>
                    <div className={styles.Text1}>Claes </div>
                    <div className={styles.Text2}>Founder and CEO</div>
                  </div>
                  <div className={styles.right}>
                    <img src={testimonal} alt="testimonal" />
                    <div className={styles.para}>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat.
                    </div>
                    <div className={styles.buttonsWrapper}>
                      <div
                        className={styles.button}
                        onClick={toggleReviewModal}
                      >
                        {/*<div className={styles.effect}></div>*/}
                        <span>Submit Your Review</span>
                        <img src={LongArrow} alt="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {slide === 4 && (
                <div
                  className={styles.testimonalCard}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.left}>
                    <div className={styles.Text1}>Claes Pellvik</div>
                    <div className={styles.Text2}>Founder and CEO</div>
                  </div>
                  <div className={styles.right}>
                    <img src={testimonal} alt="testimonal" />
                    <div className={styles.para}>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat.
                    </div>
                    <div className={styles.buttonsWrapper}>
                      <div
                        className={styles.button}
                        onClick={toggleReviewModal}
                      >
                        {/*<div className={styles.effect}></div>*/}
                        <span>Submit Your Review</span>
                        <img src={LongArrow} alt="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.learnMoreRow}>
              <div className={styles.learnMore}>
                <div className={styles.prevNext}>
                  <div
                    className={styles.arrow1}
                    onClick={() => {
                      const res = slide - 1;
                      if (res < 4) {
                        setSlide(1);
                      }
                      if (res < 1) {
                        setSlide(4);
                      } else {
                        setSlide(res);
                      }
                      setActive(!active);
                    }}
                  >
                    <img src={Prev1} alt="backward" className={styles.img1} />
                    <img src={Prev2} alt="backward" className={styles.img2} />
                  </div>
                  <div
                    className={styles.arrow2}
                    onClick={() => {
                      const res = slide + 1;
                      if (res > 4) {
                        setSlide(1);
                      } else {
                        setSlide(res);
                      }
                      setActive(!active);
                    }}
                  >
                    <img src={Next2} alt="forward" className={styles.img1} />
                    <img src={Next1} alt="forward" className={styles.img2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReviewModal isOpen={isOpenReviewModal} toggle={toggleReviewModal} />
    </>
  );
};

export default Testimonal;
