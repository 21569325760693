import React, { useState } from "react";
import styles from "./Faq.module.scss";
import faqData from "./FaqData";
import Accordion from "../../__common/Accordion/Accordion";

const FAQ = () => {
  const [Data, setData] = useState(faqData);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.Container}>
          <div className={styles.headingWrapper}>
            <div className={styles.heading}>FAQs</div>
          </div>
          <div className={styles.upperWrapper}>
            <div className={styles.innerWrapper}>
              {Data.map((currElement) => {
                const { id, question, answer } = currElement;
                return (
                  <Accordion id={id} question={question} answer={answer} />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
