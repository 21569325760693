import React from "react";
import styles from "./Why.module.scss";
import LongArrow from "../../../Assets/icons/longArrow.svg";
import Next from "../../../Assets/icons/next1.svg";
import Box2 from "../../../Assets/images/whypbox.gif";

const Why = () => {
  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.Container}>
          <div className={styles.left}>
            <div className={styles.whyText}>WHY</div>
            <div className={styles.heading}> PANDORAS BOX?</div>
            <div className={styles.text}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </div>
            <div className={styles.btn}>
              <div className={styles.button2}>
                <span>Join Community</span>
                <img src={LongArrow} alt="arrow" className={styles.img1} />
                <img src={Next} alt="arrow" className={styles.img2} />
              </div>
            </div>
          </div>

          <div className={styles.right}>
            <img className={styles.imgContainer} src={Box2} alt="left-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Why;
