import pboxContract from "../utils/pboxContract";
import Web3 from "web3";

//write functions//

export const getPboxApprovall = async (address, amount, account) => {
  console.log("preSaleAddress for PBOX>>>>>>>", address);
  console.log("amount for PBOX>>>>>>>", amount);

  let response = await pboxContract.methods
    .approve(address, amount)
    .send({ from: account });
  return response;
};

// read functions//
export const checkPboxAllowance = async (account, address) => {
  let response = await pboxContract.methods.allowance(account, address).call();
  return response;
};

export const getdynamicpboxBalance = async (account) => {
  console.log(account, "account");
  let response = await pboxContract.methods.balanceOf(account).call();
  const etherValue = Web3.utils.fromWei(response, "ether");
  return etherValue;
};
