import React from "react";
import Mybbx from "../../components/MyBbxModules/MyBbx/Mybbx";

const BBX = () => {
  return (
    <>
      <Mybbx />
    </>
  );
};

export default BBX;
