import cardBg from "../../../Assets/background/card-bg.svg";
const CardData = [
  {
    id: "1",
    title: "Treasury Revenue",
    dec: "Treasury revenue will be created via Node sales, and transaction taxes.",
    img: cardBg,
  },
  {
    id: "2",
    title: "Treasury growth",
    dec: "Treasury inflow is used to increase its own balance. Our treasury will be one that infiltrates all aspects of crypto, just like the sins did with human nature! This will allow to our treasury to survive and flourish all market conditions. ",
    img: cardBg,
  },
  {
    id: "3",
    title: "Nodes",
    dec: "Once you have purchased your node, the rewards will be collected automatically in the node and the owner can claim whenever they want.",
    img: cardBg,
  },
];
export default CardData;
