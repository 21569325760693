import React, { useState, useEffect } from "react";
import styles from "./myBbx.module.scss";
import { BBXData } from "./BBXData";
import AllTabels from "./allTabels";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { levelChecker } from "../../../store/reducers/contractReducer";
import {
  claimLevel,
  claimAllLevel,
  claimable,
  userData,
} from "../../../Actions/InvestmentActions";
import moment from "moment";
import { useAlert } from "react-alert";
import { ThreeDots } from "react-loader-spinner";

const Mybbx = () => {
  const dispatch = useDispatch();
  const { mypboxData } = useSelector((state) => state.contract);
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const alert = useAlert();
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedLevelId, setClickedLevelId] = useState(0);

  useEffect(() => {
    if (metaMaskToken) {
      dispatch(levelChecker(metaMaskToken));
    }
  }, [metaMaskToken]);
  useEffect(() => {
    // let myData = mypboxData.map((item) => item.level);
  }, [mypboxData]);
  //claim Protocol//
  const claimProtocol = async (level) => {
    debugger;
    try {
      setIsLoading(true);
      const claim = await claimLevel(level, metaMaskToken);
      alert.show("Successfully claimed", { type: "success" });
      setIsLoading(false);
    } catch (error) {
      alert.show("Please Claim After 10 Minutes", { type: "error" });
      setIsLoading(false);
    }
  };
  //claim all Protocol//
  const claimAllProtocol = async () => {
    debugger;
    let claim;
    if (metaMaskToken) {
      mypboxData.forEach(async (element) => {
        claim = await claimable(metaMaskToken, element.level);
      });
      if (claim == 0) {
        alert.show("There's nothing to claim", { type: "error" });
      } else {
        setLoading(true);
        try {
          const claimAll = await claimAllLevel(metaMaskToken);
          alert.show("Successfully claimed all levels", {
            type: "success",
          });
          setLoading(false);
        } catch (error) {
          alert.show("Transaction not Performed", { type: "error" });
          setLoading(false);
        }
      }
    } else {
      alert.show("Connect Your wallet", { type: "error" });
      setLoading(false);
    }
  };
  return (
    <>
      {!state?.level && (
        <div className={styles.container}>
          <div className={styles.ContentContainer}>
            <div className={styles.wrapper}>
              <div className={styles.headingWrapper}>
                <div className={styles.label}>My Pandoras Box</div>
                <button
                  className={styles.claimAll}
                  disabled={loading}
                  onClick={() => claimAllProtocol()}
                >
                  {loading ? (
                    <ThreeDots
                      height="30"
                      width="30"
                      color="white"
                      ariaLabel="loading"
                    />
                  ) : (
                    " Claim All"
                  )}
                </button>
              </div>
              <table cellspacing="0" cellpadding="0">
                <tr>
                  <td>Protocols</td>
                  <td>PBOX</td>
                  <td>Claim</td>
                </tr>
                {!metaMaskToken ? (
                  <span
                    style={{
                      fontSize: "20px",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    "Connect Your Wallet to Watch Protocols History"
                  </span>
                ) : mypboxData?.length > 0 ? (
                  mypboxData?.map((currElem) => {
                    const { id, level, dbn } = currElem;
                    return (
                      <tr>
                        <td>
                          <div className={styles.level}>{level}</div>
                        </td>
                        <td>
                          <div className={styles.level}>{dbn}</div>
                        </td>
                        <td>
                          <button
                            className={styles.action}
                            disabled={isLoading}
                            onClick={() => {
                              claimProtocol(id);
                              setClickedLevelId(id);
                            }}
                          >
                            {clickedLevelId === id && isLoading ? (
                              <ThreeDots
                                height="30"
                                width="30"
                                color="white"
                                ariaLabel="loading"
                              />
                            ) : (
                              "Claim"
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <span
                    style={{
                      fontSize: "20px",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    "No Transaction Found"
                  </span>
                )}
              </table>
            </div>
          </div>
        </div>
      )}
      {state?.level && <AllTabels level={state?.level} />}
    </>
  );
};

export default Mybbx;
