import { Link, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import Logo from "../../../../Assets/icons/logo.svg";
import NewLogo from "../../../../Assets/icons/logo1.svg";
import mobileNav from "../../../../Assets/icons/navicon.svg";
import React, { useEffect, useState, useRef } from "react";
import styles from "./Nav.module.scss";
import { slide as Menu } from "react-burger-menu";
import wallet from "../../../../Assets/icons/wallet.svg";
import arrow from "../../../../Assets/icons/arrow.svg";
import Next from "../../../../Assets/icons/next1.svg";
import ConnectModal from "../../WalletConnectModal/ConnectModal";
import { useSelector } from "react-redux";

const Nav = () => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const { address, account, active } = useWeb3React();
  const { pathname } = useLocation();
  const [menuOpenState, setMenuOpenState] = useState(false);
  const stateChangeHandler = (newState) => setMenuOpenState(newState.isOpen);
  const handleMenu = () => {
    setMenuOpenState(!menuOpenState);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleConnectWallet = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      {/* Mobile Menu*/}
      <Menu
        right
        disableAutoFocus
        customBurgerIcon={false}
        isOpen={menuOpenState}
        onStateChange={(state) => stateChangeHandler(state)}
        itemListElement="div"
      >
        <div className="bm-item">
          <div className="headerLogo">
            <Link to="/#" onClick={() => window.location.replace("/#")}>
              <img src={NewLogo} alt="logo" />
            </Link>
          </div>
          <div className="bm-list">
            <ul>
              <Link to="/">
                <li className="bm-item-list mainlist" onClick={handleMenu}>
                  <label for="btn-1" className="market-btn">
                    Home
                  </label>
                </li>
              </Link>
              <Link to="/ecosystem">
                <li className="bm-item-list mainlist" onClick={handleMenu}>
                  <label for="btn-2" className="market-btn">
                    Ecosystem
                  </label>
                </li>
              </Link>
              <Link to="/swap">
                <li className="bm-item-list mainlist" onClick={handleMenu}>
                  <label for="btn-4" className="market-btn">
                    Swap
                  </label>
                </li>
              </Link>
              <Link to="/staking">
                <li className="bm-item-list mainlist" onClick={handleMenu}>
                  <label for="btn-4" className="market-btn">
                    Staking
                  </label>
                </li>
              </Link>

              <Link to="/pandoras-box">
                <li className="bm-item-list mainlist" onClick={handleMenu}>
                  <label for="btn-4" className="market-btn">
                    My PBOX
                  </label>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </Menu>

      {/*Main Header */}
      <div className={styles.container}>
        {/*left side of navbar */}
        <div className={styles.leftWrapper}>
          <div className={styles.leftContainer}>
            <Link to="/#" onClick={() => window.location.replace("/#")}>
              <img className={styles.logo} src={NewLogo} alt="logo" />
            </Link>
          </div>
          <div className={styles.rightMenuWrapper}>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/" ? styles.activeItem : ""
              }`}
            >
              <Link to="/">Home</Link>
              {pathname.includes("home") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>

            <div
              className={`${styles.menuItem} ${styles.activeItem} ${
                pathname === "ecosystem" ? styles.activeItem : ""
              }`}
            >
              <Link to="/ecosystem">Ecosystem</Link>
              {pathname.includes("ecosystem") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "swap" ? styles.active : ""
              }`}
            >
              <Link to="/swap">Swap</Link>
              {pathname.includes("swap") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "staking" ? styles.active : ""
              }`}
            >
              <Link to="/staking">Staking</Link>
              {pathname.includes("staking") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>

            <div
              className={`${styles.menuItem} ${styles.active} ${
                pathname === "/pandoras-box" ? styles.active : ""
              }`}
            >
              <Link to="/pandoras-box">My PBOX</Link>
              {pathname.includes("/pandoras-box") ? (
                <div className={styles.active}></div>
              ) : null}
            </div>
          </div>
        </div>
        {/*right side of navbar */}
        <div className={styles.btnMenuWrapper}>
          <div className={styles.menubtn} onClick={toggleConnectWallet}>
            <img src={wallet} alt="wallet" className={styles.walletimg} />
            <span>
              {metaMaskToken
                ? `${metaMaskToken.substring(0, 6)}...${metaMaskToken.substring(
                    metaMaskToken?.length - 4
                  )}`
                : " Connect Wallet"}
            </span>
            <img src={arrow} alt="arrow" className={styles.nextimg1} />
            <img src={Next} alt="arrow" className={styles.nextimg2} />
          </div>
        </div>
      </div>
      <div>
        {/*mobile header*/}
        <div className={styles.mainmobileNav}>
          <Link to="/#" onClick={() => window.location.replace("/#")}>
            <img className={styles.logo} src={NewLogo} alt="logo" />
          </Link>

          <div className={styles.RightWrapper}>
            <div className={styles.menubtn} onClick={toggleConnectWallet}>
              <span>
                {metaMaskToken
                  ? `${metaMaskToken.substring(
                      0,
                      6
                    )}...${metaMaskToken.substring(metaMaskToken?.length - 4)}`
                  : " Connect Wallet"}
              </span>
            </div>
            <div
              onClick={() => setMenuOpenState(!menuOpenState)}
              className={styles.btnbtn}
            >
              <img src={mobileNav} alt="mobileNav" />
            </div>
          </div>
        </div>
      </div>
      <ConnectModal isOpen={isOpen} toggle={toggleConnectWallet} />
    </>
  );
};

export default Nav;
