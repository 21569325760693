import React from "react";
import Header from "../../components/__common/Header/Header";
import Works from "../../components/HomeModules/Works/Works";
import Staking from "../../components/HomeModules/Staking/Staking";
import Why from "../../components/HomeModules/Why/Why";
import RoadMap from "../../components/HomeModules/RoadMap/RoadMap";
import Testimonal from "../../components/HomeModules/Testimonal/Testimonal";
import FAQ from "../../components/HomeModules/FAQ/Faq";

const Home = () => {
  return (
    <>
      <Header />
      <Works />
      <Staking />
      <Why />
      <RoadMap />
      <Testimonal />
      <FAQ />
    </>
  );
};

export default Home;
