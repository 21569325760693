import React, { useState } from "react";
import styles from "./RoadMap.module.scss";
import Next from "../../../Assets/icons/Next.svg";
import Prev from "../../../Assets/icons/Prev.svg";
import icon1 from "../../../Assets/icons/icon1.png";
import icon2 from "../../../Assets/icons/icon2.png";
import icon3 from "../../../Assets/icons/icon3.png";
import worldIcon from "../../../Assets/icons/worldIcon.svg";
import Project from "../../../Assets/icons/Project.svg";
import roadmapWallet from "../../../Assets/icons/roadmapWallet.svg";
import inActiveNext from "../../../Assets/icons/inActiveNext.svg";
import inActivePrev from "../../../Assets/icons/inActivePrev.svg";

const RoadMap = () => {
  const [slide, setSlide] = useState(1);
  const handleLI = (num) => {
    const res = num;
    if (res > 4) {
      setSlide(1);
    } else {
      setSlide(res);
    }
  };
  const [active, setActive] = useState(false);
  const [isactive, setisActive] = useState(false);
  const handleActive = () => {
    setActive(!active);
  };
  const handleIsActive = () => {
    setisActive(!isactive);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.textSection}>
            <label>ROADMAP</label>
          </div>

          <div className={styles.upperWrapper}>
            <div className={styles.cardWrapper}>
              {slide === 1 && (
                <div
                  className={styles.cards}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.card}>
                    <div className={styles.leftSide}>
                      <img src={worldIcon} alt="world-icon" />
                      <div className={styles.cardContent}>
                        <span>Private Sale</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon1} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card2}>
                    <div className={styles.leftSide}>
                      <img src={Project} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Incubating Project</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon2} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card3}>
                    <div className={styles.leftSide}>
                      <img src={roadmapWallet} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Revealing & implementing all 7 Sins</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon3} alt="icon" />
                    </div>
                  </div>
                </div>
              )}
              {slide === 2 && (
                <div
                  className={styles.cards}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.card}>
                    <div className={styles.leftSide}>
                      <img src={worldIcon} alt="world-icon" />
                      <div className={styles.cardContent}>
                        <span>Private Sale</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon1} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card2}>
                    <div className={styles.leftSide}>
                      <img src={Project} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Incubating Project</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon2} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card3}>
                    <div className={styles.leftSide}>
                      <img src={roadmapWallet} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Revealing & implementing all 7 Sins</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon3} alt="icon" />
                    </div>
                  </div>
                </div>
              )}
              {slide === 3 && (
                <div
                  className={styles.cards}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.card}>
                    <div className={styles.leftSide}>
                      <img src={worldIcon} alt="world-icon" />
                      <div className={styles.cardContent}>
                        <span>Private Sale</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon1} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card2}>
                    <div className={styles.leftSide}>
                      <img src={Project} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Incubating Project</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon2} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card3}>
                    <div className={styles.leftSide}>
                      <img src={roadmapWallet} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Revealing & implementing all 7 Sins</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon3} alt="icon" />
                    </div>
                  </div>
                </div>
              )}
              {slide === 4 && (
                <div
                  className={styles.cards}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  data-aos-once={true}
                >
                  <div className={styles.card}>
                    <div className={styles.leftSide}>
                      <img src={worldIcon} alt="world-icon" />
                      <div className={styles.cardContent}>
                        <span>Private Sale</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon1} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card2}>
                    <div className={styles.leftSide}>
                      <img src={Project} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Incubating Project</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon2} alt="icon" />
                    </div>
                  </div>
                  <div className={styles.card3}>
                    <div className={styles.leftSide}>
                      <img src={roadmapWallet} alt="Project-icon" />
                      <div className={styles.cardContent}>
                        <span>Revealing & implementing all 7 Sins</span>
                        <span>Q1 2022</span>
                      </div>
                    </div>
                    <div className={styles.rightSide}>
                      <img src={icon3} alt="icon" />
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.roadmapContent}>
                <span>
                  We assist our customers in their search for market ready,
                  creative blockchain solutions that solve real world business
                  problems.
                </span>
              </div>
              <div className={styles.prevNextWrapper}>
                <div className={styles.prevNext}>
                  <div
                    className={styles.arrow1}
                    onClick={() => {
                      const res = slide - 1;
                      if (res < 4) {
                        setSlide(1);
                      }
                      if (res < 1) {
                        setSlide(4);
                      } else {
                        setSlide(res);
                      }
                    }}
                  >
                    <img src={Prev} alt="backward" />
                  </div>
                  <div
                    className={styles.arrow2}
                    onClick={() => {
                      const res = slide + 1;
                      if (res > 4) {
                        setSlide(1);
                      } else {
                        setSlide(res);
                      }
                    }}
                  >
                    <img src={Next} alt="forward" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadMap;
