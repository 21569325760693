import React, { useEffect } from "react";
import styles from "./Header.module.scss";
import Box1 from "../../../Assets/images/pbox.gif";
import arrow from "../../../Assets/icons/arrow.svg";
import Next from "../../../Assets/icons/next1.svg";
import { Link } from "react-router-dom";
import Aos from "aos";
const Header = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <div className={styles.wrapper} id="#">
        <div className={styles.ContantentWrapper}>
          <div className={styles.ContentContainer}>
            <div className={styles.container}>
              <div className={styles.left}>
                <div className={styles.heading}>
                  Welcome to Pandoras Box ! creation protocol with Nodes.
                </div>
                <div className={styles.text}>
                  We are constructing a truly decentralized financial
                  infrastructure which is geared towards success,
                  sustainability, and longevity.
                </div>

                <div className={styles.btn}>
                  <Link to="/pre-sale">
                    <div className={styles.button2}>
                      <span>PreSale</span>
                      <img src={arrow} alt="arrow" className={styles.img1} />
                      <img src={Next} alt="arrow" className={styles.img2} />
                    </div>
                  </Link>
                </div>
              </div>

              <div className={styles.right}>
                <img
                  className={styles.imgContainer}
                  src={Box1}
                  alt="left-img"
                  data-aos="zoom-in-up"
                  data-aos-duration="3000"
                  data-aos-delay="1000"
                  data-aos-once={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
