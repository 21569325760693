import Web3 from "web3";
import { priceContractAddress } from "../utils/constants/app.constants";

let priceConstant;
priceConstant = {
  contractAddress: priceContractAddress,
  abi: [
    {
      inputs: [
        { internalType: "address", name: "_factory", type: "address" },
        { internalType: "address", name: "_router", type: "address" },
        { internalType: "address", name: "_PBOX", type: "address" },
        { internalType: "address", name: "_WETH", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      inputs: [],
      name: "PBOX",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "WETH",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "factory",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amountIn", type: "uint256" }],
      name: "pboxToEth",
      outputs: [{ internalType: "uint256", name: "usd", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "router",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
  ],
};

// const web3 = new Web3(window.ethereum);
// const priceContract = new web3.eth.Contract(
//   priceConstant.abi,
//   priceConstant.contractAddress
// );

let priceContract;
if (window.ethereum) {
  const web3 = new Web3(window.ethereum);
  priceContract = new web3.eth.Contract(
    priceConstant.abi,
    priceConstant.contractAddress
  );
} else {
  const web3 = new Web3(
    "https://rinkeby.infura.io/v3/3dfb5f4a4b564ee893955cd379a3e449"
  );
  priceContract = new web3.eth.Contract(
    priceConstant.abi,
    priceConstant.contractAddress
  );
}

export default priceContract;
