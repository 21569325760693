import React from "react";
import SwapComp from "../../components/SwapModules/swapComp";

const Swap = () => {
  return (
    <>
      <SwapComp />
    </>
  );
};

export default Swap;
