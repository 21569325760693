import React from "react";
import styles from "./Staking.module.scss";
import arrowLeft from "../../../Assets/icons/arrowLeft.svg";
import LongArrow from "../../../Assets/icons/longArrow.svg";
import { Link } from "react-router-dom";

const Staking = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.textSection}>
            <label>STAKING</label>
            <div className={styles.paraWrapper}>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo.
              </p>
            </div>
            <div className={styles.buttonsWrapper}>
              <div className={styles.btn}>
                <Link to="/staking">
                  <div className={styles.button}>
                    <span>Stake Now</span>
                    <img src={arrowLeft} alt="arrow" className={styles.img1} />
                    <img src={LongArrow} alt="arrow" className={styles.img2} />
                  </div>
                </Link>
              </div>
              <div className={styles.btn}>
                <Link to="">
                  <div className={styles.button2}>
                    <span>Learn More</span>
                    <span className={styles.play}>Play Now</span>
                    <img src={arrowLeft} alt="arrow" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Staking;
