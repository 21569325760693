import investmentContract from "../utils/investmentContract";
import Web3 from "web3";

//read functions//
export const purchase = async (account, level) => {
  let response = await investmentContract.methods
    .userPurchase(account, level)
    .call();
  return response;
};
export const claimable = async (account, level) => {
  let response = await investmentContract.methods
    .getClaimable(account, level)
    .call();
  return response;
};
export const userData = async (account) => {
  let response = await investmentContract.methods
    .getAllClaimable(account)
    .call();
  return response;
};
export const estimatedEth = async (dbnConverted, account) => {
  let response = await investmentContract.methods
    .pboxToEth(dbnConverted, account)
    .call();
  const etherValue = Web3.utils.fromWei(response, "ether");
  return etherValue;
};
//write functions//
export const buyLevel = async (level, account) => {
  let price = await investmentContract.methods.basePrice(level).call();
  let response = await investmentContract.methods.purchase(level).send({
    value: price.toString(),
    from: account,
  });
  return response;
};
export const claimLevel = async (level, account) => {
  let response = await investmentContract.methods.claim(level).send({
    from: account,
  });
  return response;
};
export const claimAllLevel = async (account) => {
  let response = await investmentContract.methods.claimAll().send({
    from: account,
  });
  return response;
};
