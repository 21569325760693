import React, { useEffect, useState } from "react";
import StakingCard from "../../components/StakingModules/StakingCard/StakingCard";
import StakingUnstaking from "../../components/StakingModules/StakingUnstaking/StakingUnstaking";
import stakingCardData from "../../components/StakingModules/StakingCard/StakingCardData";
import { estimatedEth } from "../../Actions/PriceActons";
import { useDispatch, useSelector } from "react-redux";
import { transformCardData } from "../../store/reducers/contractReducer";

const Staking = () => {
  const [cardData, setCardData] = useState(stakingCardData);
  const [selectedCardData, setSelectedCardData] = useState({});
  const dispatch = useDispatch();
  const handleCardInfo = (item) => {
    setSelectedCardData({
      recievedDBN: item?.recievedDBN,
      dbnAmount: item?.dbnAmount,
      id: item?.id,
    });
  };

  useEffect(() => {
    if (cardData) {
      dispatch(transformCardData(cardData));
    }
  }, [cardData]);

  return (
    <>
      <StakingCard
        handleCardInfo={handleCardInfo}
        selectedCardData={selectedCardData}
      />
      <StakingUnstaking selectedCardData={selectedCardData} />
    </>
  );
};

export default Staking;
