import React, { useState, useEffect } from "react";
import styles from "./stakingCrads.module.scss";
import { useSelector } from "react-redux";

const StakingCard = ({ handleCardInfo }) => {
  const protocolData = useSelector((state) => state.contract.protocolData);
  const { load } = useSelector((state) => state.contract);
  console.log(load, "load");
  useEffect(() => {
    // console.log("protocolData in StakingCard", protocolData);
  }, [protocolData]);

  return (
    <>
      <div className={styles.Wrapper}>
        <div className={styles.ContentContainer}>
          <div className={styles.cardWrapper}>
            {load ? (
              <div className={styles.LoadingText}>Loading Protocols......</div>
            ) : (
              protocolData?.map((item) => {
                const {
                  id,
                  title,
                  dbn,
                  recieved,
                  dbnAmount,
                  recievedDBN,
                  estimatedEth,
                } = item;
                return (
                  <div>
                    <div
                      className={styles.card}
                      key={id}
                      onClick={() => handleCardInfo(item)}
                    >
                      <div>
                        <div className={styles.header}>
                          <label>{title}</label>
                        </div>
                        <div className={styles.description}>
                          <div className={styles.row}>
                            <div className={styles.left}>Estimated Eth</div>
                            <div className={styles.right}>
                              {estimatedEth.slice(0, 5)}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.left}>{dbn}</div>
                            <div className={styles.right}>{dbnAmount}</div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.left}>{recieved}</div>
                            <div className={styles.right}>{recievedDBN}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}

            {/*<Link to={"/my-dbn"} state={{ level: "A" }}>   </Link>
            <div className={styles.card}>
              <div className={styles.header}>
                <label>Level A</label>
              </div>
              <div className={styles.description}>
                <div className={styles.row}>
                  <div className={styles.left}>dbn</div>
                  <div className={styles.right}>15,000 dbn</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>recieved</div>
                  <div className={styles.right}>1.5%</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>Max Per Day</div>
                  <div className={styles.right}>3</div>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <label>Level B</label>
              </div>
              <div className={styles.description}>
                <div className={styles.row}>
                  <div className={styles.left}>dbn</div>
                  <div className={styles.right}>75,000 dbn</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>recieved</div>
                  <div className={styles.right}>1.43%</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>Max Per Day</div>
                  <div className={styles.right}>6</div>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <label>Level C</label>
              </div>
              <div className={styles.description}>
                <div className={styles.row}>
                  <div className={styles.left}>dbn</div>
                  <div className={styles.right}>4,000 dbn</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>recieved</div>
                  <div className={styles.right}>1.36%</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>Max Per Day</div>
                  <div className={styles.right}>10</div>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <label>Level D</label>
              </div>
              <div className={styles.description}>
                <div className={styles.row}>
                  <div className={styles.left}>dbn</div>
                  <div className={styles.right}>15,000 dbn</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>recieved</div>
                  <div className={styles.right}>1.30%</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>Max Per Day</div>
                  <div className={styles.right}>15</div>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <label>Level E</label>
              </div>
              <div className={styles.description}>
                <div className={styles.row}>
                  <div className={styles.left}>dbn</div>
                  <div className={styles.right}>750 dbn</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>recieved</div>
                  <div className={styles.right}>1.25%</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>Max Per Day</div>
                  <div className={styles.right}>25</div>
                </div>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default StakingCard;
