import React, { useState } from "react";
import Plus from "../../../Assets/icons/Plus.svg";
import Minus from "../../../Assets/icons/Minus.svg";
import styles from "./Accordion.module.scss";

function Accordion({ id, question, answer }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        className={show ? styles.activeQuestionWrapper : styles.QuestionWrapper}
        onClick={() => setShow(!show)}
      >
        <img src={show ? Minus : Plus} />
        <div className={styles.question}>{question}</div>
      </div>
      {show && (
        <>
          <div className={styles.borderWrapper}>
            <div className={styles.AnswerWrapper}>
              <div className={styles.answer}>{answer}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Accordion;
