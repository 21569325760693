import React, { useEffect, useState } from "react";
import styles from "./Works.module.scss";
import CardData from "./CardData";
import arrowLeft from "../../../Assets/icons/arrowLeft.svg";
import CardBg from "../../../Assets/background/card-bg.svg";
const Works = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textSection}>
          <label>HOW IT WORKS</label>
          <div className={styles.paraWrapper}>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo.
            </p>
          </div>

          <div className={styles.button}>
            <span>Learn More</span>
            <span className={styles.play}>Play Now</span>
            <img src={arrowLeft} alt="arrow" />
          </div>
        </div>
        <div className={styles.cardWrapper}>
          {CardData.map((dataValue) => {
            const { title, dec, id, img } = dataValue;
            return (
              <div className={styles.card} key={id}>
                <div className={styles.header}>
                  <label>{title}</label>
                </div>
                <div className={styles.description}>
                  <p>{dec}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Works;
