import React, { useEffect, useState } from "react";
import styles from "./ConnectWallet.module.scss";
import MetaMask from "../../../Assets/icons/metaMask.svg";
import minimetaMask from "../../../Assets/icons/minimetaMask.svg";
import ConnectWallet from "../../../Assets/icons/ConnectWallet.svg";
import miniwalletConnect from "../../../Assets/icons/miniwalletConnect.svg";
import ModalLayout from "../ReviewModalLayout/ModalLayout";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../Header/Connector";
import { walletconnect, resetWalletConnector } from "../../Helper/connector";
import { NETWORK } from "../../../utils/constants/app.constants";
import { setMetaMaskToken } from "../../../store/reducers/metaMaskToenReducer";
import { useDispatch } from "react-redux";

const ConnectModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const [connect, setConnect] = useState(null);
  const { active, account, library, connector, activate, deactivate } =
    useWeb3React();
  const web3reactContext = useWeb3React();

  useEffect(() => {
    if (connector === injected) {
      setConnect(0);
    } else if (connector === walletconnect) {
      setConnect(1);
    }
  }, [connector]);

  useEffect(() => {
    if (account) {
      dispatch(setMetaMaskToken(account));
      console.log("account", account);
    }
  }, [account]);

  const changeNetwork = async () => {
    console.log("log");
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [NETWORK],
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const connectHandler = async (type) => {
    setConnect(type);
    if (connector && connector.walletConnectProvider) {
      connector.walletConnectProvider = undefined;
    }
    try {
      if (type === 0) {
        await activate(injected);

        changeNetwork();
      }
      if (type === 1) {
        const walletResConnect = await activate(walletconnect);
      }
      // window.ethereum.request({ method: "eth_requestAccounts" });
      // type === 2 && (await activate());
      toggle();
    } catch (ex) {
      toggle();
      console.log(ex);
    }
  };
  return (
    <>
      <ModalLayout isOpen={isOpen} toggle={toggle}>
        <div className={styles.mainheading}>Connect Your Wallet</div>
        <div className={styles.buttonWrapper}>
          <button
            className={styles.btn1}
            onClick={() =>
              window.ethereum === undefined
                ? window
                    .open(
                      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
                      "_blank"
                    )
                    .focus()
                : connectHandler(0)
            }
          >
            <div className={styles.Metaimg}>
              <img src={MetaMask} alt="metamask" />
            </div>
            <div className={styles.miniImage}>
              <img src={minimetaMask} alt="metamask" />
            </div>
            <div className={styles.MetaText}>
              {window.ethereum === undefined ? (
                <div className={styles.MetaText}>
                  Install MetaMask Extension
                </div>
              ) : (
                <div className={styles.MetaText}>Connect to Meta Mask</div>
              )}
            </div>
            <div className={styles.outerCircle}>
              <div
                className={` ${
                  connect === 0
                    ? styles.innerCircleActive
                    : styles.innerCircleUnactive
                }`}
              ></div>
            </div>
          </button>
          <button className={styles.btn1} onClick={() => connectHandler(1)}>
            <div className={styles.Metaimg}>
              <img src={ConnectWallet} alt="walletConnect" />
            </div>
            <div className={styles.miniImage}>
              <img src={miniwalletConnect} alt="walletConnect" />
            </div>
            <div className={styles.MetaText}>Use Wallet Connect</div>
            <div className={styles.outerCircle}>
              <div
                className={` ${
                  connect === 1
                    ? styles.innerCircleActive
                    : styles.innerCircleUnactive
                }`}
              ></div>
            </div>
          </button>
        </div>
      </ModalLayout>
    </>
  );
};

export default ConnectModal;
