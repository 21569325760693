import axios, { AxiosInstance, AxiosInterceptorManager } from "axios";
import { store } from "../store";
import { BASE_URL } from "./constants/app.constants";
import { Navigate } from "react-router-dom";

export const HTTP_CLIENT = axios.create({
  baseURL: BASE_URL,
});

// HTTP_CLIENT.interceptors.request.use(
//   (config) => {
//     const { accessToken } = store.getState().auth;
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     return config;
//   },

//   (err) => {
//     return Promise.reject(err);
//   }
// );

HTTP_CLIENT.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status >= 400 &&
      error.response.status < 500 &&
      error.response.status != 409 &&
      error.response.status != 404
    ) {
      return <Navigate to="/" />;
    }

    return Promise.reject(error);
  }
);
export default HTTP_CLIENT;
