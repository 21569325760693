import React from "react";

import ComingSoon from "../../components/EcoSystemModules/ComingSoon/ComingSoon";

const Ecosystem = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default Ecosystem;
