import preSaleContract from "../utils/preSaleContract";
import Web3 from "web3";
import BigNumber from "bignumber.js";

//read functions//
export const dynamicSupply = async (account) => {
  let response = await preSaleContract.methods.dbnSupply().call();
  const etherValue = Web3.utils.fromWei(response, "ether");
  return etherValue;
};
export const purchase = async (account, level) => {
  let response = await preSaleContract.methods
    .userPurchase(account, level)
    .call();
  return response;
};

export const claimable = async (account) => {
  let response = await preSaleContract.methods.totalClaimable(account).call();
  return response;
};
//write functions//
export const buyLevel = async (level, account) => {
  let price = await preSaleContract.methods.basePrice(level).call();
  let response = await preSaleContract.methods.purchase(level).send({
    value: price.toString(),
    from: account,
  });
  return response;
};

export const claimLevel = async (level, account) => {
  let response = await preSaleContract.methods.claim(level).send({
    from: account,
  });
  return response;
};

export const claimAllLevel = async (account) => {
  let response = await preSaleContract.methods.claimAll().send({
    from: account,
  });
  return response;
};

export const getConvertedToken = async (obj, amount, account) => {
  debugger;
  try {
    const amountVal = Number(amount) * Math.pow(10, 18);
    const amountVal2 = BigNumber(amountVal).toFixed(0);
    const res = await preSaleContract.methods
      .swap(obj?.from, obj?.to, amountVal2)
      .send({ from: account });
    console.log(res, "===>res");
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
